<template>
  <section id="configuration">
    <b-alert v-if="getSettingsError" show variant="danger">{{
      getSettingsError
    }}</b-alert>
    <div v-if="isSettingsLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <h2 class="m-2">{{ $t("admin.configuration") }}</h2>
      <b-card>
        <h3>Email settings</h3>
        <hr />
        <b-form-checkbox
          id="checkbox-1"
          v-model="getSendEmail"
          class="m-2"
          name="checkbox-1"
        >
          Send email
        </b-form-checkbox>
        <b-input
          type="text"
          v-model="email"
          placeholder="Please enter a valid email"
        ></b-input>
        <hr />
        <h3>Reports</h3>
        <b-form-checkbox
          id="checkbox-Excel"
          v-model="getSendExcel"
          class="m-2"
          name="checkbox-Excel"
        >
          Send excel attachment (off = CSV)
        </b-form-checkbox>
      </b-card>
      <b-card>
        <b-form-group>
          <h4>Project settings</h4>
          <hr />
          <label></label>
          <b-form-checkbox
            id="checkbox-2"
            v-model="useProjectNo"
            class="m-2"
            name="checkbox-2"
          >
            Use project no
          </b-form-checkbox>
          <label></label>
          <b-form-checkbox
            id="checkbox-3"
            v-model="useProjectSearch"
            class="m-2"
            name="checkbox-3"
          >
            Use project search
          </b-form-checkbox>
        </b-form-group>
      </b-card>
      <b-button @click="updateSystemSettings" variant="primary">Save</b-button>
      <Back-Button class="ml-3"></Back-Button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "configurationPage",
  components: {
    BackButton
  },
  methods: {
    ...mapActions(["loadSettings", "updateSettings"]),
    async updateSystemSettings() {
      this.updateSettings().then(function() {
        this.$router.push("/order");
      });
    }
  },
  computed: {
    ...mapGetters(["getAppSettings", "isSettingsLoading", "getSettingsError"]),
    useProjectNo: {
      get() {
        return this.getAppSettings.useProjectNo;
      },
      set(value) {
        this.getAppSettings.useProjectNo = value;
      }
    },
    useProjectSearch: {
      get() {
        return this.getAppSettings.useProjectSearch;
      },
      set(value) {
        this.getAppSettings.useProjectSearch = value;
      }
    },
    getSendEmail: {
      get() {
        return this.getAppSettings.sendEmail || false;
      },
      set(value) {
        this.getAppSettings.sendEmail = value;
      }
    },
    getSendExcel: {
      get() {
        return this.getAppSettings.useExcelAttchement || false;
      },
      set(value) {
        this.getAppSettings.useExcelAttchement = value;
      }
    },
    email: {
      get() {
        return this.getAppSettings.email;
      },
      set(value) {
        this.getAppSettings.email = value;
      }
    }
  },
  mounted() {
    this.loadSettings();
  }
};
</script>

<style></style>
